import * as t from 'io-ts';
import { RawSelectFieldC, SelectFieldC } from '../functions/toSelectFormat';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { FormFieldC, FormFieldPartialWithoutRenderAsC, RawFormFieldC } from './FormField';
import { InputFieldC, RawInputFieldC } from '../functions/toInputFormat';

const RawDateTimeFieldC = t.intersection([RawFormFieldC, t.type({
    properties: t.type({
        time: t.intersection([RawFormFieldC, t.type({
            properties: t.type({
                hour: RawSelectFieldC,
                minute: RawSelectFieldC,
            }),
        })]),
        date: RawInputFieldC,
    }),
})]);

export const RawDirectDateTimeRangeC = t.intersection([
    RawFormFieldC,
    t.type({
        properties: t.type({
            startAt: RawDateTimeFieldC,
            endAt: RawDateTimeFieldC,
        }),
        renderer: t.literal(FIELD_RENDER_TYPES.DIRECT_DATE_TIME_RANGE),
    }),
]);
export type RawDirectDateTimeRange = t.TypeOf<typeof RawDirectDateTimeRangeC>;

export const DirectDateTimeRangePartialC = t.intersection([
    FormFieldPartialWithoutRenderAsC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.DIRECT_DATE_TIME_RANGE),
    }),
]);
export type DirectDateTimeRangePartial = t.TypeOf<typeof DirectDateTimeRangePartialC>;

const DateTimeFieldC = t.intersection([FormFieldC, t.type({
    fields: t.type({
        time: t.intersection([FormFieldC, t.type({
            fields: t.type({
                hour: SelectFieldC,
                minute: SelectFieldC,
            }),
        })]),
        date: InputFieldC,
    }),
})]);

export const DirectDateTimeRangeC = t.intersection([
    DirectDateTimeRangePartialC,
    FieldCommonPropsC,
    t.type({
        fields: t.type({
            startAt: DateTimeFieldC,
            endAt: DateTimeFieldC,
        }),
    }),
]);
export type DirectDateTimeRange = t.TypeOf<typeof DirectDateTimeRangeC>;
