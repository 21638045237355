import convertForm from './convertForm';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { assertIsNonNullable } from '../../../../typeAssertions/undefined';
import { RawDirectDateTimeRange, DirectDateTimeRangePartial } from '../types/DirectDateTimeRange';

export default (field: RawDirectDateTimeRange): DirectDateTimeRangePartial => {
    const convertedForm = convertForm(field);
    assertIsNonNullable(convertedForm);

    return {
        ...convertedForm,
        renderAs: FIELD_RENDER_TYPES.DIRECT_DATE_TIME_RANGE,
    };
};
