import $ from 'jquery';
import { createApp } from 'vue';
import Toast from 'vue-toastification';
import App from './App.vue';
import router from './router';
import store from './store';
import '../styles/main.scss';
import { loadInitialData } from './app/initialLoader';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.jQuery = $;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.$ = $;

const initializeApp = async () => {
    const app = createApp(App);

    await loadInitialData();

    app.use(router);
    app.use(store);
    app.use(Toast);

    app.mount('#app');
};

initializeApp();
