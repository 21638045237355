import isType from '../../../../io-ts/isType';
import Converter from '../Converter';
import toDirectDateTimeRange from '../functions/toDirectDateTimeRange';
import { RawDirectDateTimeRange, RawDirectDateTimeRangeC, DirectDateTimeRangePartial } from '../types/DirectDateTimeRange';
import { RawField } from '../types/RawField';

export default class DirectDateTimeRangeConverter extends Converter<RawDirectDateTimeRange> {
    public canConvert(field: RawField): field is RawDirectDateTimeRange {
        return isType(field, RawDirectDateTimeRangeC);
    }

    protected convertPartially(field: RawDirectDateTimeRange): DirectDateTimeRangePartial | null {
        return toDirectDateTimeRange(field);
    }
}
