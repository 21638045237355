import { RouteLocationRaw, RouteLocationNormalized } from 'vue-router';
import { RouterListener } from './RouterListener';
import { userModule } from '../../../../vue/store';
import ROUTE_NAMES from '../../../../vue/routes/routeNames';

const ALLOWED_ROUTES: ROUTE_NAMES[] = [
    ROUTE_NAMES.USER_SETTINGS,
    ROUTE_NAMES.LOGOUT,
];

export default class PasswordExpiredListener implements RouterListener {
    public async onBeforeEach(
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
    ): Promise<boolean | RouteLocationRaw> {
        if (this.isAllowedRoute(to)) {
            return true;
        }

        if (!userModule.isPasswordExpired) {
            return true;
        }

        const isFromSettings = from.name === ROUTE_NAMES.USER_SETTINGS;
        if (isFromSettings) {
            return false;
        }

        return { name: ROUTE_NAMES.USER_SETTINGS };
    }

    protected isAllowedRoute(to: RouteLocationNormalized): boolean {
        const { name } = to;
        if (!name) {
            return false;
        }

        return ALLOWED_ROUTES.includes(name as ROUTE_NAMES);
    }
}
