import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M22.7917 10.3837C23.2417 10.3837 23.6066 10.7485 23.6066 11.1986C23.6066 11.6486 23.2417 12.0135 22.7917 12.0135L20.7545 12.0135C20.3045 12.0135 19.9396 11.6486 19.9396 11.1986C19.9396 10.7485 20.3045 10.3837 20.7545 10.3837H22.7917ZM20.3469 5.60378C19.9572 5.37876 19.4588 5.51229 19.2338 5.90204L18.2152 7.6663C17.9902 8.05604 18.1237 8.55441 18.5135 8.77943C18.9032 9.00445 19.4016 8.87092 19.6266 8.48117L20.6452 6.71692C20.8702 6.32717 20.7367 5.8288 20.3469 5.60378ZM20.3469 16.8443C20.7367 16.6193 20.8702 16.1209 20.6452 15.7312L19.6266 13.9669C19.4016 13.5772 18.9032 13.4437 18.5135 13.6687C18.1237 13.8937 17.9902 14.3921 18.2152 14.7818L19.2338 16.5461C19.4588 16.9358 19.9572 17.0694 20.3469 16.8443ZM14.6841 4.6452C14.7778 4.76859 14.8283 4.91666 14.8284 5.06852L14.842 16.7346C14.8425 17.1348 14.5006 17.4589 14.0785 17.4584C13.9183 17.4582 13.7621 17.4103 13.632 17.3215L10.5938 15.2478L8.35189 15.2452C8.29563 16.2278 8.26345 17.2108 8.30574 18.1973C8.30574 18.8867 7.72466 19.4124 7.05682 19.4417C6.3527 19.4417 5.84842 18.8665 5.81699 18.1927C5.87013 17.1815 5.82572 16.1795 5.73813 15.1789C3.77015 14.8332 2.27343 13.1312 2.27105 11.0853L2.27059 10.6885C2.26792 8.39497 4.14434 6.53788 6.46169 6.54058L10.5836 6.54538L13.6171 4.47878C13.9595 4.24548 14.4372 4.31999 14.6841 4.6452ZM15.8811 8.44841C16.9379 8.78651 17.7041 9.77678 17.7055 10.9449C17.7068 12.113 16.9429 13.1015 15.8869 13.4371L15.8811 8.44841Z"
    }, null, -1)
  ])))
}
export default { render: render }