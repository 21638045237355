<template>
    <div
        class="scrollable"
        :class="{
            'scrollable--vertical': !horizontal,
            'scrollable--horizontal': horizontal,
            'scrollable--external': external,
        }"
        :style="{
            '--offset-top': offsetTopInPx,
        }"
    >
        <div
            ref="content"
            class="scrollable__content"
            :class="contentClass"
            @scroll.passive="onScroll"
        >
            <slot />
        </div>

        <div
            v-show="!hideScrollbar && hasScrollbar"
            class="scrollable__scrollbar scrollbar"
            :class="[{
                'scrollable__scrollbar--active': isActive,
                'scrollbar--active': isActive,
            }]"
        >
            <div
                class="scrollbar__track"
                :style="trackStyles"
                @mousedown="startTrackMove"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core';
import {
    computed,
    onMounted,
    onUnmounted,
    ref,
    useTemplateRef,
} from 'vue';
import * as scrollHelper from '../../../scripts/helpers/scroll';

const SCROLL_REEACH_END_DEBOUNCE = 200;

type Props = {
    horizontal?: boolean,
    contentClass?: string | null,
    offsetTop?: number;
    external?: boolean,
    hideScrollbar?: boolean,
}

type Emits = {
    scrollReachEnd: [Event],
    scroll: [Event],
}

type Point = { x: number, y: number };

const emit = defineEmits<Emits>();

const {
    contentClass = null,
    external = false,
    horizontal = false,
    offsetTop = 0,
    hideScrollbar = false,
} = defineProps<Props>();

const contentRef = useTemplateRef<HTMLElement>('content');

let observer: MutationObserver|null = null;

const trackSize = ref<number>(0);
const trackOffset = ref(0);
const hasScrollbar = ref(true);
const trackMoveLocation = ref<Point | null>(null);

const trackStyles = computed<Record<string, string>>(() => {
    const sizeProperty = horizontal ? 'width' : 'height';
    const offsetProperty = horizontal ? 'margin-left' : 'margin-top';

    return {
        [sizeProperty]: `${trackSize.value}px`,
        [offsetProperty]: `${trackOffset.value}px`,
    };
});
const isActive = computed(() => trackMoveLocation.value !== null);
const offsetTopInPx = computed(() => `${offsetTop}px`);

const emitScrollReachEnd = (event: Event) => {
    const target = event.target as HTMLElement;
    const size = horizontal ? target.offsetWidth : target.offsetHeight - offsetTop;
    const scrollableSize = horizontal
        ? target.scrollWidth
        : target.scrollHeight - offsetTop;
    const scroll = horizontal ? target.scrollLeft : target.scrollTop;

    if (size + scroll >= scrollableSize) {
        emit('scrollReachEnd', event);
    }
};

const getScroll = (): number => {
    if (!contentRef.value) {
        return 0;
    }

    return horizontal
        ? contentRef.value?.scrollLeft
        : contentRef.value?.scrollTop;
};

const getSize = (): number => {
    if (!contentRef.value) {
        return 0;
    }

    return horizontal
        ? contentRef.value.offsetWidth
        : contentRef.value.offsetHeight - offsetTop;
};

const getScrollableSize = (): number => {
    if (!contentRef.value) {
        return 0;
    }

    return horizontal
        ? contentRef.value.scrollWidth
        : contentRef.value.scrollHeight - offsetTop;
};

const refreshTrackStyles = (): void => {
    if (!contentRef.value) {
        return;
    }

    const size = getSize();
    const scrollableSize = getScrollableSize();

    const trackPercentHeight = size / scrollableSize;
    trackSize.value = trackPercentHeight * size;

    const scroll = getScroll();

    const maxOffset = size - trackSize.value;
    const maxScroll = scrollableSize - size;
    const scrollToTrackOffsetPercent = maxOffset / maxScroll || 0;
    trackOffset.value = scroll * scrollToTrackOffsetPercent;

    hasScrollbar.value = scrollableSize > size;
};

const debouncedEmitScrollReachEnd = useDebounceFn(emitScrollReachEnd, SCROLL_REEACH_END_DEBOUNCE);

const onScroll = (event: Event): void => {
    refreshTrackStyles();

    emit('scroll', event);
    debouncedEmitScrollReachEnd?.(event);
};

const startTrackMove = (event: MouseEvent) => {
    const { screenX, screenY } = event;
    trackMoveLocation.value = { x: screenX, y: screenY };
    document.body.style.userSelect = 'none';
};

const calcScrollPercent = (size: number, scrollable: number): number => {
    const maxOffset = size - trackSize.value;
    const maxScroll = scrollable - size;

    return maxScroll / maxOffset;
};

const stopTrackMove = (): void => {
    document.body.style.userSelect = '';
    if (trackMoveLocation.value === null) {
        return;
    }

    trackMoveLocation.value = null;
};

const onTrackMove = (event: MouseEvent): void => {
    const { screenX, screenY } = event;
    if (trackMoveLocation.value === null || trackSize.value === null) {
        return;
    }

    // avoid element selection
    event.preventDefault();
    event.stopPropagation();

    const { x, y } = trackMoveLocation.value;
    trackMoveLocation.value = { x: screenX, y: screenY };

    const offsetY = screenY - y;
    const offsetX = screenX - x;
    const offset = horizontal ? offsetX : offsetY;

    const size = getSize();
    const scrollableSize = getScrollableSize();
    const scrollPercent = calcScrollPercent(size, scrollableSize);

    const scroll = offset * scrollPercent;
    const scrollRounded = Math.round(scroll);

    const scrollByX = horizontal ? scrollRounded : 0;
    const scrollByY = horizontal ? 0 : scrollRounded;
    contentRef.value?.scrollBy(scrollByX, scrollByY);
};

const createRefreshObserver = (): MutationObserver => {
    const mutationObserver = new MutationObserver(refreshTrackStyles);

    if (contentRef.value) {
        mutationObserver.observe(
            contentRef.value,
            {
                childList: true,
                characterData: true,
                subtree: true,
            },
        );
    }

    return mutationObserver;
};

const onWheelScroll = (event: WheelEvent) => {
    if (horizontal && contentRef.value) {
        const isMouseWheel = Math.abs(event.deltaY) > Math.abs(event.deltaX);

        if (isMouseWheel) {
            event.preventDefault();
            contentRef.value.scrollLeft += event.deltaY;
        } else {
            contentRef.value.scrollLeft += event.deltaX;
        }
    }
};

onMounted(() => {
    refreshTrackStyles();
    observer = createRefreshObserver();

    document.addEventListener('mousemove', onTrackMove);
    document.addEventListener('mouseup', stopTrackMove);

    contentRef.value?.addEventListener('wheel', onWheelScroll);
});

onUnmounted(() => {
    if (observer) {
        observer.disconnect();
    }

    document.removeEventListener('mousemove', onTrackMove);
    document.removeEventListener('mouseup', stopTrackMove);

    contentRef.value?.removeEventListener('wheel', onWheelScroll);
});

const scrollToEnd = () => {
    if (!contentRef.value) {
        return;
    }

    scrollHelper.scrollToEnd(contentRef.value, horizontal);
};

const scrollStep = (step: number) => {
    const value = getScroll() + step;

    contentRef.value?.scrollTo({
        behavior: 'smooth',
        top: horizontal ? 0 : value,
        left: horizontal ? value : 0,
    });
};

defineExpose({
    scrollToEnd,
    scrollStep,
});

</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/z-indexes';

$scrollbar-track-size: 0.1875rem;
$scrollbar-track-size-on-hover: $scrollbar-track-size * 2;
$scrollbar-padding: 0.125rem;

@mixin hide-default-scrollbar() {
    -ms-overflow-style: none; // IE

    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
        display: none; // Chrome, Safari
    }
}

.scrollable {
    position: relative;

    &--vertical {
        height: 100%;
    }

    &--horizontal {
        width: 100%;
    }

    &--external {
        $scrollbar-external-offset: $scrollbar-track-size-on-hover + $spacing-lm;
        flex-grow: 1;

        width: calc(100% + #{$scrollbar-external-offset});
        padding-right: $scrollbar-external-offset;
    }

    &__content {
        @include hide-default-scrollbar;

        height: 100%;
    }

    &--vertical &__content {
        overflow-y: auto;
    }

    &--horizontal &__content {
        overflow-x: auto;
    }

    &__scrollbar {
        opacity: 0;

        transition: opacity 0.5s;
    }

    &__scrollbar--active,
    &:hover > &__scrollbar {
        opacity: 1;
    }
}

.scrollbar {
    position: absolute;
    z-index: $scroll-bar-z-index;

    .scrollable--vertical & {
        top: var(--offset-top);
        right: 0;

        width: min-content;
        height: calc(100% - var(--offset-top));
        padding: 0 $scrollbar-padding;
    }

    .scrollable--horizontal & {
        bottom: 0;
        left: 0;

        width: 100%;
        height: min-content;
        padding: $scrollbar-padding 0;
    }

    &__track {
        background-color: var(--theme-color-surface-scroll);
        border-radius: 2px;

        transition: width 0.5s, height 0.5s, background-color 0.5s;

        &:hover {
            cursor: pointer;

        }
    }

    .scrollable--vertical &__track {
        width: $scrollbar-track-size;
    }

    .scrollable--horizontal &__track {
        height: $scrollbar-track-size;
    }

    &--active &__track,
    &:hover &__track {
        width: $scrollbar-track-size-on-hover;

        border-radius: 3px;
    }
}
</style>
