<template>
    <div
        ref="wrapper"
        class="menu-wrapper"
    >
        <ul
            class="menu__nav"
            role="navigation"
            :class="{preload: !doesMenuHasAnimation}"
        >
            <li class="list__element">
                <RouterLink
                    :to="homeRoute"
                    data-cy="logo-link"
                >
                    <Logo
                        variant="no-text"
                        class="logo"
                        alt="logo"
                    />
                </RouterLink>
            </li>
            <li class="list__element main-elements">
                <ul
                    v-if="!isAnonymous"
                    class="list"
                    role="navigation"
                    aria-label="Main menu"
                >
                    <Tooltip
                        v-for="menuItem in menuItems"
                        :id="`${menuItem.routeName}-tooltip`"
                        :key="menuItem.routeName"
                        :title="menuItem.text"
                        placement="right"
                        no-min-width
                    >
                        <li class="list-element">
                            <RouterLink
                                class="nav-link"
                                :class="{'nav-link--active' : routeIsActive(menuItem.routeNamesToHighlight) }"
                                :data-cy="`menu-item-${menuItem.text.toLowerCase()}`"
                                :aria-label="menuItem.text"
                                :to="{name: menuItem.routeName}"
                            >
                                <Component
                                    :is="menuItem.inlinedSvg"
                                    alt="Link icon"
                                />
                                <span class="nav-link__text">{{ menuItem.text }}</span>
                            </RouterLink>
                        </li>
                    </Tooltip>
                </ul>
            </li>
            <li
                v-if="!isAnonymous"
                class="user-info"
                data-cy="user-info-button"
            >
                <Tooltip
                    id="notifications-tooltip"
                    title="Notifications"
                    placement="right"
                    no-min-width
                >
                    <NotificationsButton
                        id="notifications-button"
                        :is-active="isNotificationsPopupShown"
                        :has-new-notification="newNotificationsCount > 0"
                        @notifications-toggle="toggleNotificationsPopup"
                    />
                </Tooltip>

                <Tooltip
                    id="profile-tooltip"
                    title="Profile"
                    placement="right"
                    no-min-width
                    class="user-picture-tooltip"
                >
                    <button
                        class="user-picture"
                        type="button"
                        aria-label="Profile Menu"
                        @click="switchToPopup"
                    >
                        <img
                            :src="userAvatarPath || defaultAvatar"
                            width="32"
                            height="32"
                            class="img-rounded-circle"
                            alt="Circle avatar"
                        >
                        <div
                            v-if="unreadAlertsCount > 0"
                            class="user-picture__notification-dot"
                            data-cy="user-picture-notification-dot"
                        />
                    </button>
                </Tooltip>
            </li>
        </ul>
        <NotificationsPopup
            v-if="isNotificationsPopupShown"
            :notifications="notifications"
            @scroll-reach-end="loadNextNotifications"
            @reload-notifications="reloadNotifications"
            @hide-notifications="isNotificationsPopupShown = false"
        />
        <TheUserInfoPopup
            v-if="isPopupShown"
            ref="userInfoPopup"
            :user-name="userName"
            :unread-alerts-count="unreadAlertsCount"
            @hide="hideMenu"
        />
    </div>
</template>

<script lang="ts" setup>
import { type RouteLocationRaw, useRoute } from 'vue-router';
import { debounce } from 'lodash-es';
import {
    computed, nextTick, onUnmounted, ref, watch,
} from 'vue';
import ClickOutsideListener from '../../../scripts/helpers/listeners/ClickOutsideListener';
import {
    alertModule, isAnonymousModule, mediaQueriesModule, menuModule, notificationModule, userModule,
} from '../../store';
import userIconUrl from '../../../images/user.svg?url';
import TheUserInfoPopup from './TheUserInfoPopup.vue';
import hasPermissionForRoute from '../../../scripts/helpers/functions/permissions/hasPermissionForRoute';
import Logo from '../SvgComponents/Logo.vue';
import ROUTE_NAMES from '../../routes/routeNames';
import { MENU_ITEMS } from './constants/menuItems';
import type { MenuItem } from './types/MenuItem';
import { getCountOfUnreadAlerts } from '../../../scripts/api/alert/AlertApi';
import { allPermissions } from '../../../scripts/test/mocks/allPermissions';
import NotificationsButton from '../Notifications/NotificationsButton.vue';
import NotificationsPopup from '../Notifications/NotificationsPopup.vue';
import { getPopupNotifications } from '../../../scripts/api/notification/NotificationApi';
import { NotificationItem, NotificationItemC } from '../../../scripts/types/Notification';
import assertIsTypeC, { decodeAsTypeC } from '../../../scripts/typeAssertions/isTypeC';
import DateHelper from '../../../scripts/helpers/DateHelper';
import Tooltip from '../Elements/Tooltip.vue';
import { RecordMessageC, Type } from '../../../scripts/types/sockets/record';
import { Channel, useSocket } from '../../composables/socket';

const route = useRoute();

const defaultAvatar = userIconUrl;
const homeRoute: RouteLocationRaw = { name: ROUTE_NAMES.HOME };
const formFilterName = 'notification_filter';

const unreadAlertsCount = computed(() => alertModule.unreadAlertsCount);
const isLgOrMore = computed(() => mediaQueriesModule.isLgOrMore);
const isMenuShown = computed(() => menuModule.isMenuShown);
const isPopupShown = computed(() => menuModule.isPopupShown);
const doesMenuHasAnimation = computed(() => menuModule.doesMenuHasAnimation);
const userName = computed(() => userModule.userName);
const isAnonymous = computed(() => isAnonymousModule.isAnonymous);
const userAvatarPath = computed(() => userModule.userAvatarPath);
const permissions = computed(() => userModule.permissions);

const userInfoPopup = ref<typeof TheUserInfoPopup>();
const clickOutsideListener = ref<ClickOutsideListener | null>(null);
const notificationsPage = ref(1);
const notificationsPageCount = ref(1);
const notifications = ref<NotificationItem[]>([]);
const newNotificationsCount = ref(0);
const isNotificationsPopupShown = ref(false);
const wrapper = ref<HTMLDivElement>();

const getFormFieldName = (field: string, prefix = ''): string => (
    `${formFilterName}${prefix}[${field}]`
);

const hasAlertsPermission = computed(() => (
    userModule.hasPermission(allPermissions.generalAlert)
));
const loadAlertsCount = async () => {
    try {
        const count = await getCountOfUnreadAlerts();
        alertModule.setUnreadAlertsCount(count);
    } catch (e) {
        notificationModule.notifyUser('Unable to load alerts.');
    }
};

const buildNotificationFilter = (): FormData => {
    const formData = new FormData();
    formData.append('page', notificationsPage.value.toString());
    formData.append(
        getFormFieldName('left_date', '[startedAt]'),
        DateHelper.toDateFormFormat(new Date(0)),
    );
    formData.append(getFormFieldName('viewed'), 'n');

    return formData;
};

const loadNotifications = async (): Promise<void> => {
    try {
        const {
            paginator: { items, paginationData },
            newNotificationsCount: newCount,
        } = await getPopupNotifications(buildNotificationFilter());

        notifications.value = [...notifications.value, ...items];
        notificationsPageCount.value = paginationData.pageCount;
        newNotificationsCount.value = newCount;
    } catch (e: unknown) {
        if (userModule.isUserLoggedIn) {
            notificationModule.notifyUser('Unable to load notifications.');
        } else {
            notificationModule.hideNotification();
        }
    }
};

const hideMenu = (): void => {
    menuModule.hideMenu();
};

const loadNotificationsDebounced = debounce(loadNotifications, 500);

const reloadNotifications = async () => {
    notificationsPage.value = 1;
    notifications.value = [];

    loadNotificationsDebounced();
};

const onNotificationSocket = async (json: string) => {
    const parsedJson = JSON.parse(json);
    const message = decodeAsTypeC(parsedJson, NotificationItemC);

    if (message.receiverId === userModule.userId) {
        reloadNotifications();

        if (userModule.isUserLoggedIn && userModule.isNotificationSoundEnabled) {
            const player = new Audio('/notification_sound.mp3');
            await player.play();
        }
    }
};

const toggleNotificationsPopup = (): void => {
    isNotificationsPopupShown.value = !isNotificationsPopupShown.value;
};

const hasChildren = (permission: string): boolean => permissions.value?.some((p) => p.startsWith(`${permission}--`)) ?? false;

const menuItems = computed<MenuItem[]>(() => MENU_ITEMS.filter(({ routeName }) => {
    if (routeName === allPermissions.admin && !hasChildren(allPermissions.admin)) {
        return false;
    }
    return hasPermissionForRoute({ name: routeName });
}));

const routeIsActive = (routeNamesToHighlight: string[]): boolean => (
    route.matched.some(({ name }) => (
        routeNamesToHighlight.some((routeName) => name === routeName)
    ))
);

const switchToPopup = () => {
    menuModule.switchToPopup();
};

const loadNextNotifications = async (): Promise<void> => {
    if (notificationsPage.value >= notificationsPageCount.value) {
        return;
    }
    notificationsPage.value = Math.min(notificationsPageCount.value, notificationsPage.value + 1);
    await loadNotifications();
};

watch(isMenuShown, () => {
    if (!isMenuShown.value) {
        return;
    }

    if (hasAlertsPermission.value) {
        loadAlertsCount();
    }

    if (userModule.isUserLoggedIn) {
        loadNotifications();
    }
});

watch(() => userModule.isUserLoggedIn, () => {
    if (userModule.isUserLoggedIn) {
        loadNotifications();
    }
}, { immediate: true });

watch(hasAlertsPermission, () => {
    if (!hasAlertsPermission.value) {
        return;
    }

    loadAlertsCount();
}, { immediate: true });

watch(isLgOrMore, () => {
    if (isLgOrMore.value) {
        return;
    }

    menuModule.disableAnimationUntilShown();
});

watch(isPopupShown, async () => {
    if (!isPopupShown.value) {
        clickOutsideListener.value?.unregisterListener();
        clickOutsideListener.value = null;
        return;
    }

    await nextTick();

    clickOutsideListener.value = new ClickOutsideListener(
        [
                userInfoPopup.value?.$el as HTMLElement,
                wrapper.value as HTMLElement,
        ], hideMenu,
    );
    clickOutsideListener.value.registerListener();
});

watch(route, () => {
    isNotificationsPopupShown.value = false;
});

useSocket(Channel.NOTIFICATION, onNotificationSocket);
useSocket(Channel.COMMENT_DELETE, reloadNotifications);
useSocket(Channel.RECORD, (data) => {
    const message = JSON.parse(data);
    assertIsTypeC(message, RecordMessageC);

    if (message.type === Type.DELETE) {
        reloadNotifications();
    }
});

onUnmounted(() => {
    clickOutsideListener.value?.unregisterListener();
    loadNotificationsDebounced.cancel();
});

</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/svg-colors';
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/colors_old';
@import '../../../styles/abstracts/font-sizes';
@import '../../../styles/abstracts/z-indexes';
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';
@import '../../../styles/pages/menu';

$nav-width-mobile: 20.5rem;
$user-picture-side-mobile: 2.6rem;
$user-picture-side-desktop: 2.25rem;

.preload {
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
    animation-duration: 0s !important;
}

.menu-wrapper {
    display: flex;

    height: 100%;
}

.menu__nav {
    z-index: $the-menu-z-index;

    display: flex;

    flex-direction: column;

    justify-content: space-between;

    justify-items: center;

    min-width: $nav-width-desktop;

    height: 100%;

    padding: 0;

    background-color: var(--theme-color-surface-menu);
}

.logo {
    height: 4rem;
}

.main-elements {
    font-size: $font-size-lg;
}

.list {
    display: flex;

    flex-direction: column;

    height: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    :deep(.tooltip-wrapper) {
        width: 100%;
    }

    &__element {
        display: flex;

        flex-direction: column;
    }
}

.nav-link {
    display: flex;

    justify-content: center;

    padding: $spacing-smm $spacing-s;

    margin: $spacing-xxs $spacing-xxs 0 $spacing-xxs;

    color: var(--theme-color-text-primary);

    cursor: pointer;

    border-radius: $border-radius-sm;

    transition: 0.3s background-color;

    svg {
        height: $icon-size-lg;

        margin-right: 0;

        fill: var(--theme-color-icon-inversed-secondary);

        transition: fill 0.3s;
    }

    &--active,
    &:hover {
        background-color: var(--theme-color-surface-secondary-default);

        svg {
            fill: var(--theme-color-icon-always-white);
        }
    }

    &__text {
        display: none;
    }
}

.user-info {
    display: flex;

    flex-flow: column;

    align-items: center;

    justify-content: center;

    justify-self: flex-end;

    width: 100%;

    padding: 0;

    margin-bottom: $spacing-m;

    cursor: pointer;
}

.user-picture-tooltip {
    display: flex;

    justify-content: center;

    width: 100%;
}

.user-picture {
    position: relative;

    padding: 0;

    background-color: transparent;

    border: 0;

    &,
    img {
        width: $user-picture-side-desktop;
        height: $user-picture-side-desktop;
    }

    img {
        object-fit: cover;
        background: var(--theme-color-icon-always-white);
    }

    &__notification-dot {
        @include notification-dot(0.7rem, var(--theme-color-surface-menu));
    }
}

.img-rounded-circle {
    border-radius: 50%;
}

@keyframes slide-right {
    0% {
        left: -$nav-width-mobile;
    }

    100% {
        left: 0;
    }
}

@keyframes slide-left {
    0% {
        left: 0;
    }

    100% {
        left: -$nav-width-mobile;
    }
}
</style>
