import * as t from 'io-ts';

export const TemplateC = t.type({
    id: t.number,
    name: t.string,
    commentColor: t.string,
    fields: t.array(t.type({
        id: t.number,
        title: t.string,
    })),
    type: t.string,
    isHidden: t.boolean,
});

export type Template = t.TypeOf<typeof TemplateC>;
