import { Theme } from '../constants/themes';

const THEMES_DIR_PATH = '/themes';

/**
 * Sets the application's theme by dynamically loading a CSS file.
 * @param theme - The theme to set.
 * @returns Promise<void> - Resolves when the theme is applied.
 */
export async function setTheme(theme: Theme): Promise<void> {
    const themePath = `${THEMES_DIR_PATH}/_${theme}.css`;

    const isThemeAlreadyApplied = (
        !!document.head.querySelector<HTMLLinkElement>(`link[href="${themePath}"]`)
    );

    if (isThemeAlreadyApplied) {
        return;
    }

    // Remove the currently applied theme, if any
    const currentThemeLink = document.head.querySelector<HTMLLinkElement>(`link[href^="${THEMES_DIR_PATH}/_"]`);
    currentThemeLink?.remove();

    // Create a new link element for the new theme
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = themePath;

    link.onerror = () => {
        console.error(`Failed to load theme: ${theme}`);
    };

    document.head.appendChild(link);
}
