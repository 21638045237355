import AnnouncementIcon from '../../../images/announcement-2.svg?component';
import ROUTE_NAMES from '../routeNames';
import SECTION_NAMES from '../sectionNames';
import { permissionsModule as pM } from '../../store';

const meta = {
    title: SECTION_NAMES.ANNOUNCEMENT,
    icon: AnnouncementIcon,
    permissions: (): string[] => [pM.allPermissions.adminAnnouncement],
};

const AdminAnnouncementView = () => import('../../views/admin/AdminAnnouncement/AdminAnnouncementView.vue');

export default [
    {
        path: '',
        name: ROUTE_NAMES.ADMIN_ANNOUNCEMENT_LIST,
        component: () => import('../../views/admin/AdminAnnouncement/AdminAnnouncementList.vue'),
        meta,
    },
    {
        path: 'new',
        name: ROUTE_NAMES.ADMIN_ANNOUNCEMENT_NEW,
        component: AdminAnnouncementView,
        meta,
    },
    {
        path: 'edit/:id',
        name: ROUTE_NAMES.ADMIN_ANNOUNCEMENT_VIEW,
        component: AdminAnnouncementView,
        meta,
    },
];
