import { Variant } from '../../../vue/components/Elements/Button/Variant';

export default class NotificationButton {
    constructor(
        public text: string,
        public readonly callback = (): any => true,
        public variant: Variant | null = null,
        public cypressSelector: string | null = null,
        public disabled: boolean = false,
    ) {
    }
}
