import Converter from './Converter';
import { RawField } from './types/RawField';

import ZoomAccountConverter from './classes/ZoomAccountConverter';
import UserFilterConverter from './classes/UserFilterConverter';
import RadioFieldConverter from './classes/RadioFieldConverter';
import SelectFieldConverter from './classes/SelectFieldConverter';
import FileFieldConverter from './classes/FileFieldConverter';
import MultiFilesFieldConverter from './classes/MultiFilesFieldConverter';
import CheckboxFieldConverter from './classes/CheckboxFieldConverter';
import FilterInfoItemsContainerConverter from './classes/FilterInfoItemsContainerConverter';
import MultiSelectFieldConverter from './classes/MultiSelectFieldConverter';
import ArrayFieldConverter from './classes/ArrayFieldConverter';
import QuickButtonFieldConverter from './classes/QuickButtonFieldConverter';
import EvaluationDescriptionFieldConverter from './classes/EvaluationDescriptionFieldConverter';
import EvaluationImageFieldConverter from './classes/EvaluationImageFieldConverter';
import EvaluationStaticTextFieldConverter from './classes/EvaluationStaticTextFieldConverter';
import RetentionRuleFieldConverter from './classes/RetentionRuleFieldConverter';
import DateFieldConverter from './classes/DateFieldConverter';
import ReportDateFieldConverter from './classes/ReportDateFieldConverter';
import CommentReviewFieldConverter from './classes/CommentReviewFieldConverter';
import ExpirationFieldConverter from './classes/ExpirationFieldConverter';
import FormFieldConverter from './classes/FormFieldConverter';
import RadioCheckboxTreeFieldConverter from './classes/RadioCheckboxTreeFieldConverter';
import TranscriptsCardsFieldConverter from './classes/TranscriptsCardsFieldConverter';
import AudioFieldConverter from './classes/AudioFieldConverter';
import InputFieldConverter from './classes/InputFieldConverter';
import HiddenFieldConverter from './classes/HiddenFieldConverter';
import SectionalFormConverter from './classes/SectionalFormConverter';
import GridSizeFieldConverter from './classes/GridSizeConverter';
import MultipleChoiceConverter from './classes/MultipleChoiceConverter';
import SingleChoiceConverter from './classes/SingleChoiceConverter';
import UsersGroupsChoiceConverter from './classes/UsersGroupsChoiceConverter';
import RightsChoiceConverter from './classes/RightsChoiceConverter';
import FileUploadConverter from './classes/FileUploadConverter';
import ColorPickerConverter from './classes/ColorPickerConverter';
import AvatarConverter from './classes/AvatarConverter';
import RadioChoiceConverter from './classes/RadioChoiceConverter';
import ScheduleRulesConverter from './classes/ScheduleRulesConverter';
import AsyncTypeaheadConverter from './classes/AsyncTypeaheadConverter';
import PresetsControlConverter from './classes/PresetsControlConverter';
import DateFilterConverter from './classes/DateFilterConverter';
import UsersGroupsFilterConverter from './classes/UsersGroupsFilterConverter';
import DateRangeConverter from './classes/DateRangeConverter';
import DirectDateRangeConverter from './classes/DirectDateRangeConverter';
import DirectDateTimeRangeConverter from './classes/DirectDateTimeRangeConverter';
import DurationConverter from './classes/DurationConverter';
import RestrictAccessConverter from './classes/RestrictAccessConverter';
import StaticTextFieldConverter from './classes/StaticTextFieldConverter';
import EvaluationCollapseConverter from './classes/EvaluationCollapseConverter';
import StaticImageFieldConverter from './classes/StaticImageFieldConverter';

const converters: ReadonlyArray<Converter<RawField>> = [
    new StaticTextFieldConverter(),
    new RestrictAccessConverter(),
    new AsyncTypeaheadConverter(),
    new GridSizeFieldConverter(),
    new ColorPickerConverter(),
    new AvatarConverter(),
    new ZoomAccountConverter(),
    new SectionalFormConverter(),
    new RightsChoiceConverter(),
    new UsersGroupsChoiceConverter(),
    new UsersGroupsFilterConverter(),
    new MultipleChoiceConverter(),
    new RadioChoiceConverter(),
    new SingleChoiceConverter(),
    new FileUploadConverter(),
    new ScheduleRulesConverter(),
    new PresetsControlConverter(),
    new DateFilterConverter(),
    new DateRangeConverter(),
    new DirectDateRangeConverter(),
    new DirectDateTimeRangeConverter(),
    new DurationConverter(),
    // enum
    new UserFilterConverter(),
    new RadioFieldConverter(),
    new SelectFieldConverter(),
    // by type
    new CheckboxFieldConverter(),
    // by type that don't follow specification
    new FileFieldConverter(),
    new MultiFilesFieldConverter(),
    new RadioCheckboxTreeFieldConverter(),
    new TranscriptsCardsFieldConverter(),
    // array
    new FilterInfoItemsContainerConverter(),
    new EvaluationCollapseConverter(),
    new MultiSelectFieldConverter(),
    new ArrayFieldConverter(),
    // object
    new QuickButtonFieldConverter(),
    new EvaluationDescriptionFieldConverter(),
    new EvaluationImageFieldConverter(),
    new EvaluationStaticTextFieldConverter(),
    new RetentionRuleFieldConverter(),
    new DateFieldConverter(),
    new ReportDateFieldConverter(),
    new CommentReviewFieldConverter(),
    new ExpirationFieldConverter(),
    new FormFieldConverter(),
    // inputs
    new AudioFieldConverter(),
    new InputFieldConverter(),
    new HiddenFieldConverter(),
    new StaticImageFieldConverter(),
];

export default converters;
