import { AxiosError } from 'axios';
import { setGlobalSettings } from '../../scripts/helpers/globalSettings';
import appApi from '../../scripts/api/app/AppApi';
import { permissionsModule, userModule } from '../store';

const isAxiosErrorWithStatus = (error: unknown, status: number): boolean => (
    error instanceof AxiosError && error.response?.status === status
);

const loadGlobalSettings = async () => {
    try {
        const globalSettings = await appApi.getGlobalSettings();
        setGlobalSettings(globalSettings);
    } catch (e) {
        console.error('Failed to load global settings:', e);
    }
};

const loadPermissions = async () => {
    try {
        const { all, user } = await appApi.getPermissions();
        permissionsModule.setAllPermissions(all);
        userModule.setPermissions(user);
    } catch (e) {
        if (isAxiosErrorWithStatus(e, 403)) {
            return;
        }

        console.error('Failed to load permissions:', e);
    }
};

export const loadUserDetails = async () => {
    try {
        const details = await appApi.getUserDetails();
        userModule.setUserDetails(details);
    } catch (e) {
        userModule.reset();

        if (isAxiosErrorWithStatus(e, 403)) {
            return;
        }

        console.error('Failed to load user details:', e);
    }
};

export const loadInitialData = async (): Promise<void> => {
    const results = await Promise.allSettled([
        loadGlobalSettings(),
        loadPermissions(),
        loadUserDetails(),
    ]);

    results.forEach((result) => {
        if (result.status === 'rejected') {
            console.error('Error during initial data loading:', result.reason);
        }
    });
};
